@use '../abstracts/variables' as *;

.dialog-container {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 32px;

  @media screen and (min-width: $tablet-breakpoint) {
    padding: 32px 48px;
  }

  .title {
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }

  .btn-group {
    gap: 24px 48px;
    padding: 0;

    @media screen and (max-width: $tablet-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
    }

    button.btn {
      min-width: 100px;
      margin: 0;

      @media screen and (min-width: $tablet-breakpoint) {
        flex: 1;
      }
    }

    .cancel {
      @media screen and (max-width: $tablet-breakpoint) {
        order: 1;
      }
    }
  }
}
