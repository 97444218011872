/* stylelint-disable scss/at-mixin-argumentless-call-parentheses */
@use '@angular/material' as mat;

$general-typography: mat.define-typography-config(
  $headline-5: mat.define-typography-level(32px, 40px, 400),
  $headline-6: mat.define-typography-level(24px, 32px, 400),
  $subtitle-1: mat.define-typography-level(20px, 28px, 400),
  $body-1: mat.define-typography-level(18px, 24px, 400),
  $body-2: mat.define-typography-level(16px, 24px, 400),
  $caption: mat.define-typography-level(14px, 16px, 400),
  $button: mat.define-typography-level(14px, 16px, 500),
);

@include mat.all-component-typographies($general-typography);
@include mat.core();
