@use '../../../assets/styles/abstracts/variables' as *;

.success-snackbar.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    min-height: 68px !important;
    color: $color-white;
    background-color: $color-green;
  }

  .error {
    display: none;
  }
}

.error-snackbar.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    min-height: 68px;
    color: $color-white;
    background-color: $color-red;
  }

  .success {
    display: none;
  }
}
