@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  color: $color-black;
  font-size: 16px;
  -webkit-tap-highlight-color: transparent;
}

.flex-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 100%;
  padding: 16px;
  overflow: auto;

  @media screen and (min-width: $tablet-breakpoint) {
    padding: 24px;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    height: 100%;
  }
}

.flex-header {
  &-title {
    display: flex;
    gap: 16px;
    align-items: center;
    word-break: break-word;
  }
}

.flex-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;

  .table-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
  }

  .no-data {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }

  &.flex-none {
    flex: none;
  }
}

table {
  width: 100%;
}

th,
td {
  vertical-align: middle;
}

.flex-header-controls {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .control-section {
    display: flex;
    gap: 16px;

    @media screen and (min-width: $tablet-breakpoint) {
      gap: 24px;
      justify-content: flex-end;
    }

    & > button {
      flex: 1;

      @media screen and (min-width: $tablet-breakpoint) {
        flex: initial;
      }
    }
  }
}

.filter-section {
  display: flex;
  flex-direction: column;
  gap: 24px 16px;

  @media screen and (min-width: $mobile-breakpoint) and (max-width: $tablet-breakpoint) {
    flex-wrap: wrap;
  }

  @media screen and (min-width: $mobile-breakpoint) {
    flex-direction: row;
  }

  @media screen and (min-width: $tablet-breakpoint) {
    gap: 24px;
  }

  &__dropdown {
    flex: 1;
  }

  &__input {
    order: 1;

    @media screen and (min-width: $tablet-breakpoint) {
      order: 0;
    }

    @media screen and (max-width: $tablet-breakpoint) {
      flex: 1 1 100%;
    }

    @media screen and (min-width: $desktop-breakpoint) {
      flex: 1 1 auto;
    }
  }
}

.text-ellipsis {
  max-width: 138px;

  @include text-ellipsis;
}
