@use '../abstracts/mixins' as *;

.file-input {
  display: none;
}

.file-upload {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0 16px;
}

.file-name {
  font-size: 18px;
  direction: rtl;
  @include text-ellipsis;

  .inner-wrapper {
    direction: ltr;
    unicode-bidi: bidi-override;
  }
}
