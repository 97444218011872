@use '../abstracts/variables' as *;

.form-layout {
  .flex-content {
    align-items: center;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    @media screen and (min-width: $tablet-breakpoint) {
      width: 600px;
    }

    .btn-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 16px;

      @media screen and (min-width: $tablet-breakpoint) {
        flex-direction: row;
      }

      .cancel {
        order: 1;

        @media screen and (min-width: $tablet-breakpoint) {
          order: 0;
        }
      }

      & > button {
        @media screen and (min-width: $tablet-breakpoint) {
          flex: 1;
        }
      }
    }
  }
}

.error-message {
  color: $color-red;
  font-size: 14px;
}
