// -------------------------- Breakpoints -------------------------------
$desktop-breakpoint: 960px;
$tablet-breakpoint: 768px;
$mobile-breakpoint: 480px;

// -------------------------- Colors -------------------------------
$color-black: #17191c;
$color-light-gray: #eaeaea;
$color-white: #fff;
$color-blue: #0062af;
$color-green: #04cd00;
$color-red: #f44336;
$color-yellow: #ffc700;
