@use '../../../assets/styles/abstracts/variables' as *;

.mat-mdc-paginator {
  padding: 4px;
  border-top: 1px solid $color-light-gray;
}

.mat-mdc-row:nth-child(even) td {
  background-color: $color-light-gray;
}
