.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 48px;
  text-transform: uppercase;
  border-radius: 0;
}

.mat-mdc-button-base.mdc-fab--mini {
  width: 48px;
  height: 48px;
}
